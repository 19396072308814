import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FilteredDatePicker, Loading, Modal, NewSelect, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { booleanOptions, slotBookingOptions } from '@epcbuilder/lib/models/generic';
import { JobSurvey } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import useSurveyBookingAvailableSlots from '@/hooks/surveys/useSurveyBookingAvailableSlots';
import { SurveyBooking, SurveyBookingForm, SurveySaveBooking } from '@/models/job';
import { patchSurveyBooking, putSurveyBooking } from '@/network/surveys';
import { getDefaultSlot, getSlotAvailabilityForDate } from '@/utils/availableSlots';

const JobSurveyModal = ({
  onClose,
  surveyBooking,
  survey,
}: {
  onClose: () => void;
  surveyBooking: SurveyBooking | undefined;
  survey: JobSurvey | undefined;
}) => {
  const defaultValues = {
    paid: surveyBooking?.paid || false,
    surveyDate: surveyBooking?.surveyDate ? new Date(surveyBooking?.surveyDate) : undefined,
    assessor: surveyBooking?.assessorName || '',
    bookingSlot: surveyBooking?.bookingSlot,
    notes: surveyBooking?.notes || '',
  };

  const {
    register,
    setError,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SurveySaveBooking>({ defaultValues });

  const onSubmit: SubmitHandler<SurveySaveBooking> = async (data) => {
    try {
      if (surveyBooking) {
        if (data.surveyDate) {
          data.surveyDate = new Date(new Date(data.surveyDate).setHours(0, 0, 0, 0));
        }
        await patchSurveyBooking({ surveyBookingId: surveyBooking?.id, data });
      } else {
        if (survey && survey.id && data.surveyDate && data.bookingSlot) {
          const bookingDetails: SurveyBookingForm = {
            surveyId: survey!.id,
            surveyDate: new Date(surveyDate!),
            slot: data.bookingSlot!,
            state: 2,
            active: true,
            notes: data?.notes,
          };

          putSurveyBooking(bookingDetails);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error('Unable to create survey booking. Please ensure you have selected an available date and slot', {
            toastId: 'survey-booking-error',
          });
          return;
        }
      }
      toast.success('Survey booking updated', { toastId: 'survey-booking-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<SurveySaveBooking>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0);

  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 28);
  maxDate.setHours(0, 0, 0);

  const slotAvailability = useSurveyBookingAvailableSlots({ start: today, end: maxDate }).availableSlots;
  const availableDays = slotAvailability?.filter((x) => x.available).map((x) => x.date);

  // allow the currently selected booking date regardless
  if (surveyBooking?.surveyDate && !availableDays?.includes(surveyBooking?.surveyDate))
    availableDays?.push(surveyBooking?.surveyDate);

  const daysToRemove = slotAvailability?.filter((x) => !availableDays?.includes(x.date)).map((x) => x.date);

  const [availableSlots, setAvailableSlots] = useState(slotBookingOptions);

  const surveyDate = watch('surveyDate');
  useEffect(() => {
    const newSlotAvailability = getSlotAvailabilityForDate(slotAvailability, surveyDate, surveyBooking);

    setAvailableSlots(newSlotAvailability);
    setValue('bookingSlot', getDefaultSlot(newSlotAvailability, surveyDate, surveyBooking));
  }, [slotAvailability, surveyDate, surveyBooking, setValue]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="job-pashub-modal" onClose={onClose}>
      <h1>Edit Survey Details</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex flex-col gap-4">
        <NewSelect
          control={control}
          id="paid"
          name="paid"
          title="select paid"
          label="Paid"
          options={booleanOptions}
          className="rounded-xl"
        />
        <div className="flex gap-2">
          <div className="w-3/5">
            <FilteredDatePicker
              daysToRemove={daysToRemove!}
              maxDate={maxDate}
              id="surveyDate"
              control={control}
              name="surveyDate"
              label="Selected booking date"
            />
          </div>
          <div className="w-2/5">
            <NewSelect
              control={control}
              id="bookingSlot"
              name="bookingSlot"
              title="Select booking slot"
              label="Slot"
              placeholder=""
              className="mt-2 rounded-xl"
              options={availableSlots}
            />
          </div>
        </div>
        <TextInput
          className="bg-dark/10 dark:bg-light/80 cursor- cursor-not-allowed"
          disabled
          id="assessor"
          {...register('assessor')}
          label="Assessor"
          error={errors.assessor?.message}
        />
        <TextInput id="notes" {...register('notes')} label="Notes" error={errors.notes?.message} />
        <div className="mt-4 flex flex-row items-center justify-between gap-4">
          <NewButton id="cancel-button" variant="secondary" onClick={onClose} text="Cancel" />
          <NewButton
            id="access-submit"
            loading={isSubmitting}
            variant="primary"
            text="Submit"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default JobSurveyModal;
